<template>
  <div>
    <!-- have to set position: inherit; sue to default value make element overflow by some reason -->
    <Dropdown
      ref="dropdown"
      v-model="selectedMetric"
      :options="metrics"
      :disabled="appliedToAll"
      option-label="name"
      style="width: 250px;"
      placeholder="Select one metric group"
      :class="`bf${campaignId}`"
      @show="onOpen"
      @hide="onClose"
    >
      <template
        #header
      >
        <div class="header">
          <router-link
            :to="{name: 'metrics', query}"
          >
            Create new metric group
          </router-link>
        </div>
      </template>
    </Dropdown>
  </div>
</template>

<script>

import { ref } from 'vue';

export default {
  inject: ['query'],
  props: {
    campaignId: {
      type: String,
      required: true,

    },
    metric: {
      type: Object,
      default: undefined,
    },
    appliedToAll: {
      type: Boolean,
      required: true,
    },
    metrics: {
      type: Object,
      default: undefined,
    },
    onUpdate: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      selectedMetric: this.metric,
    };
  },
  watch: {
    selectedMetric: {
      handler(newValue, oldValue) {
        if (!newValue?.id) {
          this.selectedMetric = ref(null);
        } else {
          this.selectedMetric = newValue;
        }

        if (!oldValue?.id && !newValue?.id) {
          return;
        }

        this.onUpdate(oldValue?.id ? oldValue : null, newValue?.id ? newValue : null);
      },
    },
  },
  mounted() {
    const disabledDropdown = document.querySelectorAll(`.p-disabled.bf${this.campaignId}`);
    if (!disabledDropdown.length) return;
    disabledDropdown[0].classList.remove('p-disabled');
    disabledDropdown[0].classList.add('bf-disabled');
    const thiz = this;
    this.$refs.dropdown.onClick = function () {
      thiz.$confirm.require({
        message: `Please disable the "Select all campaigns” option from ${thiz.metric.name} metric group if you want to change this setting`,
        target: disabledDropdown[0],
        acceptLabel: 'Go to Metrics page',
        header: 'Metric cannot be deselected',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          thiz.$router.push({ path: '/settings/metrics' });
        },
      });
    };
  },
  methods: {
    onSelectedClick() {
      this.selectedMetric = ref(null);
    },
    onOpen() {
      const elements = document.querySelectorAll('.p-dropdown-item.p-highlight');
      elements.forEach((el) => {
        el.addEventListener('click', this.onSelectedClick);
      });
    },
    onClose() {
      const elements = document.querySelectorAll('.p-dropdown-item.p-highlight');
      elements.forEach((el) => {
        el.removeEventListener('click', this.onSelectedClick);
      });
    },
  },
  clickListener(event) {
    this.$confirm.require({
      message: `${this.metric.name}`,
      target: event.currentTarget,
      acceptLabel: 'Go to Rules page',
      header: 'Rule cannot be deselected',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.$router.push({ path: '/metrics' });
      },
    });
  },

};
</script>

<style>
.cursor-pointer {
  cursor: pointer;
}
.header {
  margin: 1.25rem 1.25rem 0 1.25rem;;
}
</style>
